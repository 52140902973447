<template>
  <v-dialog
    :content-class="contentClass"
    :value="show"
    :width="width"
    v-bind="$attrs"
    @click:outside="clickedOutside"
    @keydown.esc="closeDialog"
    tabindex="1"
  >
    <v-icon v-if="showClose" id="closeModal" @click="closeDialog"
      >mdi-close</v-icon
    >
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: "TDialogWrapper",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showClose: {
      type: Boolean,
      default: true,
      required: false,
    },
    width: {
      type: String,
      default: "570",
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    contentClass() {
      return "t-dialog-wrapper " + this.classes;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    clickedOutside() {
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.t-dialog-wrapper {
  position: relative;
  .mdi-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    z-index: 1;
  }
}
::v-deep .v-dialog--fullscreen {
  border-radius: 0px !important;
}
</style>
